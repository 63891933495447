<template lang="pug">
ClientOnly(v-if="couponCode")
  Teleport(to="#banner")
    .mgm-award-notification(@click="openPopup")

      .progress-bar-content

        .progress-bar-text
          span(v-if="referrals == 2")
            b É OFICIAL: você ganhou seu boné!!

          span(v-else-if="referrals == 1")
            | O boné já é quase seu, falta apenas&#32;
            b 1 indicação!

          span(v-else="referrals == 0")
            b Indique 2 amigas&#32;
            | e ganhe um boné Magenta!

        .progress-bar-dots

          .progress-bar-dot(:class="{ current: referrals == 0, filled: referrals >= 1 }")
            img.icon(src="~/assets/dashboard/promotions/202501-bone/person-icon.svg")

            img.check(src="~/assets/dashboard/promotions/202501-bone/check.svg")

          .progress-bar-dot(:class="{ current: referrals == 1, filled: referrals >= 2 }")
            img.icon(src="~/assets/dashboard/promotions/202501-bone/person-icon.svg")

            img.check(src="~/assets/dashboard/promotions/202501-bone/check.svg")

          .progress-bar-dot(:class="{ filled: referrals >= 2 }")
            img.icon(src="~/assets/dashboard/promotions/202501-bone/cap-icon.svg")

            img.check(src="~/assets/dashboard/promotions/202501-bone/check.svg")

  MGMAwardPopup(
    v-model:open="popupOpen",
    :couponCode="couponCode"
  )

</template>
<script setup lang="ts">
import customerService from '~/services/customer/customerService'
import mgmAwardCampaignService from '~/services/promotions/memberGetMember/memberGetMemberAwardCampaignService'

import MGMAwardPopup from '~/promotions/202501-bone/MGMAwardPopup.vue'

const referrals = ref<number | null>(null)
const couponCode = ref<string | null>(null)

const reload = async () => {
  await Promise.all([
    customerService.findSelfMemberGetMemberCoupon(),
    mgmAwardCampaignService.getRedeemCount('202501-bone')
  ])
  .then(([mgmCouponResponse, redeemCountResponse]) => {
    couponCode.value = mgmCouponResponse?.couponId
    referrals.value = redeemCountResponse?.redeemCount
  })
  .catch(() => {
    couponCode.value = null
    referrals.value = null
  })
}

const popupOpen = ref(false)

const openPopup = () => {
  popupOpen.value = true
}

await reload()
</script>
<style lang="sass">
@import "~/assets/styles/mixins"
@import "~/assets/styles/variables"

.mgm-award-notification
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  width: 100vw
  height: 56px
  padding: 0 32px
  background-color: $magenta
  cursor: pointer
  user-select: none

  .progress-bar-content
    position: relative
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    width: 100%
    height: auto
    max-width: 980px

    .progress-bar-text
      color: white
      font-size: 16px
      text-align: center

    .progress-bar-dots
      display: flex
      flex-direction: row
      justify-content: flex-start
      align-items: center

      .progress-bar-dot
        position: relative
        width: 190px
        height: 36px

        .icon
          position: absolute
          bottom: 0
          left: 1px
          width: auto
          height: 16px
          opacity: 0.5

        .check
          display: none
          position: absolute
          top: 4px
          left: 3px
          height: 9px
          z-index: 10

        &::before
          content: ''
          position: absolute
          top: 6px
          left: 0
          width: 100%
          height: 4px
          background-color: #FF94C3

        &::after
          content: ''
          position: absolute
          width: 16px
          height: 16px
          border-radius: 50%
          background-color: #FF94C3
          top: 0
          left: 0
          box-sizing: border-box

        &:last-child
          width: 16px

          &::before
            display: none

        &.current
          .icon
            opacity: 1

          &::after
            border: 2px solid white

        &.filled
          .icon
            opacity: 1

          .check
            display: inline-block

          &::before
            background-color: white

          &::after
            background-color: white

@include phablet
  .mgm-award-notification
    height: 80px

    .progress-bar-content
      flex-direction: column
      gap: 8px

      .progress-bar-text
        font-size: 14px

      .progress-bar-dots

        .progress-bar-dot
          width: 114px
          height: 35px

          .icon
            left: 2px
            height: 15px

          .check
            top: 4px
            left: 3px
            height: 9px

          &::before
            top: 7px
            height: 3px

          &::after
            width: 17px
            height: 17px

          &:last-child
            width: 17px
</style>
