<template lang="pug">
ClientOnly
  Teleport(to="#modals")
    .dialog-popup(v-if="open")
      .dismiss(@click.stop.prevent="close")

      .content

        .content-crop(:style="{ background, width }")
          slot

        .dismiss-button(@click.stop.prevent="close") X

</template>
<script setup lang="ts">
import { useAppScroll } from '~/store/appScroll'

const props = defineProps({
  open: {
    type: Boolean,
    required: true
  },
  width: {
    type: String,
    default: '640px'
  },
  background: {
    type: String,
  }
})

const emit = defineEmits(['update:open'])

const appScroll = useAppScroll()

const close = () => {
  emit('update:open', false)
}

const watchOpen = watch(() => props.open, (open) => {
  if (open) {
    appScroll.disableScroll()
  } else {
    appScroll.enableScroll()
  }
})

onUnmounted(() => {
  watchOpen()
})
</script>
<style lang="sass">
@import '~/assets/styles/mixins'
@import '~/assets/styles/variables'

$popup-max-width: 92vw

.dialog-popup
  position: fixed
  display: flex
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  z-index: 9998
  align-items: center
  justify-content: center
  padding: 18px

  .dismiss
    position: absolute
    top: -50vh
    left: -50vw
    width: 200vw
    height: 200vh
    background-color: rgba(0, 0, 0, 0.25)
    animation: fade-in ease-out .16s forwards

  .content
    position: relative
    padding: 0
    color: #000
    border-radius: 8px
    text-align: center
    z-index: 9999
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.2)
    opacity: 0
    transform: translateY(32px)
    animation: fade-in ease-out .16s .16s forwards, slide-up ease-out .16s .16s forwards

    @keyframes slide-up
      from
        transform: translateY(32px)

      to
        transform: translateY(0)

    .content-crop
      position: relative
      max-width: $popup-max-width
      border-radius: 8px
      overflow: hidden
      cursor: pointer

    .dismiss-button
      position: absolute
      top: -16px
      right: -16px
      width: 32px
      height: 32px
      border-radius: 16px
      background-color: $magenta
      box-shadow: 0 4px 16px 0 rgba(238, 61, 138, 0.4)
      color: #FFF
      line-height: 32px
      font-weight: bold
      text-align: center
      cursor: pointer

      &:hover
        background-color: scale-color($magenta, $lightness: +15%)
</style>
