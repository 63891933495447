<template lang="pug">
Popup(v-model:open="open")
  .bone-promotion-popup(@click="goTo('oferta', 'start')")
    picture
      source(
        srcset="~/assets/dashboard/promotions/202501-bone/202501-bone-popup.webp 1x, ~/assets/dashboard/promotions/202501-bone/202501-bone-popup-2x.webp 2x",
        width="640",
        height="640",
        type="image/webp"
      )
      img(
        src="~/assets/dashboard/promotions/202501-bone/202501-bone-popup.png",
        width="640",
        height="640"
      )
</template>
<script setup lang="ts">
const route = useRoute()

const open = ref(false)

const giftCoupons = ['querobone']

const giftCoupon = computed(() => {
  const coupon = route.query.coupon

  if (coupon) {
    if (Array.isArray(coupon)) {
      return coupon.find(c => c != null && giftCoupons.includes(c.toLowerCase()))
    } else {
      return giftCoupons.includes(coupon.toLowerCase()) ? coupon : null
    }
  }

  return null
})

const hasGiftCoupon = computed(() => {
  const coupon = giftCoupon.value

  if (coupon) {
    return giftCoupons.includes(coupon.toLowerCase())
  }

  return false
})

const goTo = (elementId: string, block: ScrollLogicalPosition = 'center') => {
  open.value = false

  document.getElementById(elementId)?.scrollIntoView({
    behavior: "smooth",
    block,
  })
}

var openTimeout: ReturnType<typeof setTimeout>
onMounted(() => {
  if (hasGiftCoupon.value) {
    open.value = true
  }
})

onUnmounted(() => {
  clearTimeout(openTimeout)
})
</script>
<style lang="sass">
.bone-promotion-popup
  picture
    img
      width: 100%
      height: auto
      vertical-align: middle
</style>
