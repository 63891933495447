<template lang="pug">
.cancel-subscription-renewal
  h1 Renovação Automática

  .cancel-subscription-renewal-form(v-if="!hasSent")

    p
      | A renovação automática da sua assinatura está agendada para dia o&#32;
      b {{ formattedDate(cancellationInfo.nextRenewalAt) }}.

    p
      | Se você decidiu que não quer renovar sua assinatura após receber todas as Boxes Magenta, nos conte o motivo!&#32;
      b Em pouco tempo entraremos em contato para te ajudar e prosseguir com o cancelamento.

    Alert(type="warning", v-if="hasCancellationFee")
      span
        b Atenção:&#32;
        | Se desejar interromper o seu contrato de assinatura imediatamente, ao invés de cancelar a sua renovação automática,&#32;
        | por favor entre em contato pelo&#32;
        a(href="https://atendimento.boxmagenta.com.br/hc/pt-br/requests/new", target="_blank")
          | Fale com a gente&#32;
          fa(:icon="['fa', 'external-link-alt']")

    br

    form(@submit.prevent="send")

      SelectInput(
        v-model="state.cancelReason",
        label="Motivo do cancelamento",
        @blur="v$.cancelReason.$touch",
        :errors="v$.cancelReason.$errors",
      )
        option(v-for="reason in shuffledCancelReasons" :key="reason.value" :value="reason.value") {{ reason.description }}

      TextAreaInput(
        v-model="state.cancelReasonComment",
        label="Comentário sobre o motivo",
        rows="5",
        @blur="v$.cancelReasonComment.$touch",
        :errors="v$.cancelReasonComment.$errors",
      )

      button(v-show="false", type="submit")

      .spacer(style="height: 16px")

      .flex-centered
        Button(
          href="",
          label="Enviar solicitação",
          size="large",
          rounded,
          :loading="sending",
          @click.prevent="send"
        )

  .cancel-subscription-sent(v-else)

    p Solicitação de cancelamento enviada!&#32;
    p
      b Nosso prazo é de até 2 dias úteis para resposta, então logo entraremos em contato para prosseguir com o cancelamento da sua renovação.

    .spacer(style="height: 32px")

    .flex-centered
      Button(
        :to="{ name: 'dashboard' }"
        label="Voltar para minha conta",
        size="large",
        rounded
      )

</template>
<script setup lang="ts">
import PancakeService from '~/services/pancake/pancakeService'
import { useActiveSubscription } from '~/store/subscription/subscription'

import useVuelidate from '@vuelidate/core'
import { required } from '~/scripts/validators/validators'

useHead({
  title: 'Solicitar cancelamento da renovação'
})

definePageMeta({
  requiresAuth: true,
  breadcrumb: [
    {
      title: 'Minha conta',
      link: { name: 'dashboard' },
    },
    {
      title: 'Dados de pagamento',
      link: { name: 'edit-payment-profile' },
    },
    {
      title: 'Renovação Automática',
      link: { name: 'cancel-subscription-renewal' },
    },
  ],
})

const cancelReasons = [
  { value: 'TOO_MANY_PRODUCTS', description: 'Acúmulo de produtos' },
  { value: 'SINGLE_BOX', description: 'Box Avulsa/Presente' },
  { value: 'TRAVEL_OR_TRIP', description: 'Mudança/Viagem' },
  { value: 'DID_NOT_AUTHORIZE_RENEWAL', description: 'Não autorizei a renovação automática' },
  { value: 'DID_NOT_RECEIVE_GIFT', description: 'Não recebi o presente da promoção/cupom' },
  { value: 'LOGISTICAL_ISSUE', description: 'Questões logísticas' },
  { value: 'PRODUCT_SELECTION_ISSUE', description: 'Questões com a seleção de produtos' },
  { value: 'HEALTH_PROBLEMS', description: 'Questões de saúde' },
  { value: 'FINANCIAL_PROBLEMS', description: 'Questões financeiras' },
  { value: 'TRY_COMPETITOR', description: 'Testar outras assinaturas' },
  { value: 'NOT_INTERESTED', description: 'Não tenho mais interesse' },
  { value: 'OTHER', description: 'Outra razão' },
]

const shuffledCancelReasons = cancelReasons
  .map(value => ({ value, sort: value.value == "OTHER" ? 1 : Math.random() }))
  .sort((a, b) => a.sort - b.sort)
  .map(({ value }) => value)

const notifications = useNotifications()

const activeSubscriptionStore = useActiveSubscription()

const breadcrumbItems = ref([
  {
    title: 'Minha conta',
    link: { name: 'dashboard' },
  },
  {
    title: 'Dados de pagamento',
    link: { name: 'edit-payment-profile' },
  },
  {
    title: 'Solicitar cancelamento da renovação',
    link: { name: 'cancel-subscription-renewal' },
  },
])

const sending = ref(false)
const sent = ref(false)

type SubscriptionCancellation = {
  subscriptionId: number
  requestedAt: number
}

const cancellationRequestedLocalStorage =  useLocalStorage<string | null>('subscriptionCancellationRequested', () => null)
const TWO_DAYS = 1000 * 60 * 60 * 24 * 3
const hasSent = computed(() => {
  const subscriptionId = activeSubscriptionStore.subscription?.id
  const lastSent = JSON.parse(cancellationRequestedLocalStorage.value ?? 'null') as SubscriptionCancellation | null

  const alreadySent = lastSent ? subscriptionId == lastSent.subscriptionId && new Date().getTime() - lastSent.requestedAt < TWO_DAYS : false

  return sent.value || alreadySent
})

const state = reactive({
  cancelReason: "",
  cancelReasonComment: ""
});

const rules = {
  cancelReason: {
    required
  },
  cancelReasonComment: {
    required
  }
}

const v$ = useVuelidate(rules, state)

const monthsToRenewal = computed(() => {
  const cancelInfo = cancellationInfo.value

  if (cancelInfo == null) {
    return 0
  }

  return (cancelInfo.monthsPerPeriod - cancelInfo.monthsIntoPeriod)
})

const cancellationFee = computed(() => {
  const cancelInfo = cancellationInfo.value

  if (cancelInfo == null) {
    return 0
  }

  return cancelInfo.fee
})

const hasCancellationFee = computed(() => {
  return cancellationFee.value > 0 && monthsToRenewal.value > 1
})

const send = async () => {
  if (!await v$.value.$validate()) {
    return
  }

  const subscriptionId = activeSubscriptionStore.subscription!!.id

  sending.value = true
  try {
    const request = {
      cancelReason: state.cancelReason,
      cancelReasonComment: state.cancelReasonComment.trim()
    }

    await PancakeService.requestSubscriptionCancellation(subscriptionId, request)

    sent.value = true
    cancellationRequestedLocalStorage.value = JSON.stringify({ subscriptionId, requestedAt: new Date().getTime() })
  } catch (e) {
    captureException(new Error('Failed to request subscription cancellation', { cause: e }))

    notifications.error('Ocorreu um erro ao solicitar o cancelamento da sua renovação automática. Por favor, tente novamente mais tarde.')
  } finally {
    sending.value = false
  }
}

if (activeSubscriptionStore.subscription == null) {
  navigateTo({ name: 'dashboard' })
}

const { data: cancellationInfo, error } = await useAsyncData(() => PancakeService.getSubscriptionCancelInfo(activeSubscriptionStore.subscription!!.id))
reportOnError(error, 'Failed to load subscription cancel info')
</script>
<style lang="sass" scoped>
@import '~/assets/styles/mixins'
@import '~/assets/styles/variables'

.cancel-subscription-renewal
  position: relative
  max-width: 708px
  margin: 0 auto
  padding: 0 14px
  text-align: left
  animation: fade-in .2s forwards

  @include mobile
    h1
      font-size: 28px

  p
    b
      color: $magenta
</style>
