
export interface CustomerRedeemCountResponse {
  customerId: number,
  campaignId: string,
  minimumForAward: number,
  redeemCount: number
}

export default {
  async getRedeemCount(campaignId: string): Promise<CustomerRedeemCountResponse> {
    return await apiGet(`self/promotions/member-get-member/award-campaign/${campaignId}/redeem-count`)
  }
};
