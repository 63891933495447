<template lang="pug">
.wrapper
  .banner
    MGMAwardProgressBanner

  .dashboard-home(v-if="!loading && customer != null")
    h1 Olá, {{ customer.firstName }}!

    SubscriptionStatus(:subscription="primarySubscription", :invoices="invoices")
    SubscriptionBoxOrders(:orders="subscriptionBoxOrders")
    Magazines(:orders="subscriptionBoxOrders")
    Benefits(:subscription="primarySubscription", :benefits="benefits")

  .dashboard-home(v-else)
    .dashboard-home-loading
      Loading
</template>
<script setup lang="ts">
import type { FetchError } from 'ofetch'
import type { Benefit } from '~/services/benefit/benefitService'
import type { Customer } from '~/services/customer/customerService'
import type { PrimarySubscription } from '~/services/subscription/subscriptionService'
import type { SubscriptionBoxOrder } from '~/services/subscription/subscriptionBoxOrder/subscriptionBoxOrderService'

import MGMAwardProgressBanner from '~/promotions/202501-bone/MGMAwardProgressBanner.vue'

import SubscriptionStatus from './Subscription/SubscriptionStatus.vue'
import SubscriptionBoxOrders from './Subscription/SubscriptionBoxOrders/SubscriptionBoxOrders.vue'
import Magazines from './Subscription/Magazines/Magazines.vue'
import Benefits from './Benefits/Benefits.vue'

import CustomerService from '~/services/customer/customerService'
import { useActiveSubscription } from '~/store/subscription/subscription'
import { useAuth } from '~/store/auth'
import InvoiceService, { type Invoice } from '~/services/invoice/invoiceService'

const auth = useAuth()
const activeSubscription = useActiveSubscription()

useHead({
  title: 'Box Magenta - Minha conta'
})

definePageMeta({
  requiresAuth: true,
  breadcrumb: [
    {
      title: 'Minha conta',
      link: { name: 'dashboard' },
    },
  ],
})

const { data, error, pending: loading } = await useAsyncData('dashboard', () => {
  const activeSubscriptionId = activeSubscription.subscription?.id

  return Promise.all([
    CustomerService.findSelfCustomer(),
    CustomerService.findSelfSubscriptionBoxOrders()
      .then((orders) => orders.filter((order) => order.status != 'CANCELED'))
      .catch((error: FetchError) => {
        if (error.status == 404) {
          return []
        } else {
          throw error
        }
      }),
    CustomerService.findSelfBenefits()
      .catch((error: FetchError) => {
        if (error.status == 404) {
          return []
        } else {
          throw error
        }
      }),
    (activeSubscriptionId ? InvoiceService.findBySubscriptionId(activeSubscriptionId) : Promise.resolve(null))
  ])
})

const customer = computed(() => data.value?.[0] as Customer | null)
const primarySubscription = computed(() => activeSubscription.subscription)
const subscriptionBoxOrders = computed(() => data.value?.[1] as SubscriptionBoxOrder[])
const benefits = computed(() => data.value?.[2] as Benefit[])
const invoices = computed(() => data.value?.[3] as Invoice[])

reportOnError(error, 'Failed to load customer dashboard data')
</script>
<style lang="sass" scoped>
.dashboard-home
  position: relative
  max-width: 988px
  margin: 0 auto
  padding: 0 14px
  text-align: left
  animation: fade-in .2s forwards

  .dashboard-home-loading
    display: flex
    height: 320px
    justify-content: center
    align-items: center

  h1
    font-size: 32px
    font-weight: 300
</style>
