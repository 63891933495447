<template lang="pug">
.layout
  NuxtLoadingIndicator(:duration="1000", color="repeating-linear-gradient(90deg, rgba(255,181,213,1) 0%, rgba(238,61,138,1) 75%, rgba(159,0,69,1) 100%)")

  MGMAwardNotification(v-if="showMGMAwardNotification")

  .header
    Header

  .breadcrumb-container(v-if="breadcrumbEnabled && breadcrumbItems && breadcrumbItems.length > 1")
    Breadcrumb(:items="breadcrumbItems")

  .content
    slot

  .footer(:class="{ 'hide-mobile': hideFooterOnMobile }")
    Footer
</template>
<script setup lang="ts">
import MGMAwardNotification from '~/promotions/202501-bone/MGMAwardNotification.vue'

const route = useRoute()

const breadcrumbEnabled = true
const breadcrumbItems = computed(() => route.meta.breadcrumb)

const hideFooterOnMobile = computed(() => route.name !== 'home' && route.name !== 'dashboard')

const showMGMAwardNotification = computed(() => route.path.startsWith("/minha-conta/") && route.name !== 'dashboard')
</script>
<style lang="sass" scoped>
@import '~/assets/styles/mixins'

.layout
  position: relative
  width: 100%

  .breadcrumb-container
    position: relative
    width: 100%
    max-width: 1096px
    margin: 16px auto 32px auto
    padding: 0 8px
    align-items: center

    @include phablet
      margin: 8px auto

    @include mobile
      display: none

  .content
    position: relative
    width: 100%
    flex-grow: 1
    min-height: calc(100vh - 124px - 352px)
    box-sizing: border-box

  @include phablet
    .content
      min-height: 0
      padding-bottom: 32px

    .footer.hide-mobile
      display: none
</style>
