<template lang="pug">
Popup(v-if="couponCode", :open="props.open", @update:open="emit('update:open', $event)")
  .mgm-award-popup
    picture
      source(
        srcset="~/assets/dashboard/promotions/202501-bone/mgm-bone-popup.webp 1x, ~/assets/dashboard/promotions/202501-bone/mgm-bone-popup-2x.webp 2x",
        width="640",
        height="640",
        type="image/webp"
      )
      img(
        src="~/assets/dashboard/promotions/202501-bone/mgm-bone-popup.png",
        width="640",
        height="640"
      )

    .coupon-code(@click="copyCouponToClipboard") {{ couponCode }}
</template>
<script setup lang="ts">
const props = defineProps({
  open: {
    type: Boolean,
    required: true
  },
  couponCode: {
    type: String,
    default: null
  }
})

const emit = defineEmits(['update:open'])

const notifications = useNotifications()

const copyCouponToClipboard = () => {
  const coupon = props.couponCode

  if (coupon) {
    copyToClipboard(coupon)

    notifications.success('Cupom de desconto copiado!')
  }
}
</script>
<style lang="sass">
@import "~/assets/styles/mixins"
@import "~/assets/styles/variables"

.mgm-award-popup

  picture

    img
      width: 100%
      height: auto
      vertical-align: middle

  .coupon-code
    display: inline-flex
    position: absolute
    bottom: 12%
    left: 50%
    transform: translateX(-50%)
    flex-direction: column
    justify-content: center
    align-items: center
    width: 282px
    height: 48px
    text-align: center
    font-size: 24px
    font-weight: 800
    background-color: white
    color: $magenta
    padding: 8px 16px
    border: none
    border-radius: 36px
    margin-top: 8px
    cursor: pointer
    user-select: none

    @include upperBreakpoint(extrasmall)
      width: 240px
      height: 36px
      font-size: 18px
</style>
