<template lang="pug">
.award-progress-banner(v-if="couponCode && referrals !== null")

  .progress-bar

    .progress-bar-content

      .progress-bar-text
        span(v-if="referrals == 2")
          b É OFICIAL: você ganhou seu boné!!

        span(v-else-if="referrals == 1")
          |  O boné já é quase seu, falta apenas&#32;
          b 1 indicação!

        span(v-else="referrals == 0")
          b Indique 2 amigas&#32;
          | e ganhe um boné Magenta!

      .progress-bar-dots

        .progress-bar-dot(:class="{ current: referrals == 0, filled: referrals >= 1 }")
          img.icon(src="~/assets/dashboard/promotions/202501-bone/person-icon.svg")

          img.check(src="~/assets/dashboard/promotions/202501-bone/check.svg")

        .progress-bar-dot(:class="{ current: referrals == 1, filled: referrals >= 2 }")
          img.icon(src="~/assets/dashboard/promotions/202501-bone/person-icon.svg")

          img.check(src="~/assets/dashboard/promotions/202501-bone/check.svg")

        .progress-bar-dot(:class="{ filled: referrals >= 2 }")
          img.icon(src="~/assets/dashboard/promotions/202501-bone/cap-icon.svg")

          img.check(src="~/assets/dashboard/promotions/202501-bone/check.svg")


  .banner
    .banner-background
      .banner-background-desktop
        picture
          source(
            srcset="~/assets/dashboard/promotions/202501-bone/background-desktop.webp 1x, ~/assets/dashboard/promotions/202501-bone/background-desktop-2x.webp 2x"
            type="image/webp"
          )
          source(
            srcset="~/assets/dashboard/promotions/202501-bone/background-desktop.png 1x, ~/assets/dashboard/promotions/202501-bone/background-desktop-2x.png 2x"
            type="image/png"
          )
          img(src="~/assets/dashboard/promotions/202501-bone/background-desktop.png")

      .banner-background-mobile
        picture
          source(
            srcset="~/assets/dashboard/promotions/202501-bone/background-mobile.webp 1x, ~/assets/dashboard/promotions/202501-bone/background-mobile-2x.webp 2x, ~/assets/dashboard/promotions/202501-bone/background-mobile-3x.webp 3x"
            type="image/webp"
          )
          source(
            srcset="~/assets/dashboard/promotions/202501-bone/background-mobile.png 1x, ~/assets/dashboard/promotions/202501-bone/background-mobile-2x.png 2x, ~/assets/dashboard/promotions/202501-bone/background-mobile-3x.png 3x"
            type="image/png"
          )
          img(src="~/assets/dashboard/promotions/202501-bone/background-mobile.png")

    .banner-content
      img.banner-headline(src="~/assets/dashboard/promotions/202501-bone/headline.svg", alt="traga 2 amigas e ganhe nosso boné exclusivo!")

      .banner-subtext
        | elas assinam com seu cupom de desconto de R$30 e você garante seu brinde na próxima box

      .banner-coupon
        | copie seu cupom:

        .banner-coupon-code(@click.prevent="copyCouponToClipboard") {{ couponCode }}

      .banner-notice
        | *oferta válida até dia 27/01 ou até esgotarem os estoques e limitada a 1 (um) boné por assinante

</template>
<script setup lang="ts">
import CustomerService from '~/services/customer/customerService'
import memberGetMemberAwardCampaignService from '~/services/promotions/memberGetMember/memberGetMemberAwardCampaignService'

const notifications = useNotifications()

const referrals = ref<number | null>(null)
const couponCode = ref<string | null>(null)

const reload = async () => {
  await Promise.all([
    CustomerService.findSelfMemberGetMemberCoupon(),
    memberGetMemberAwardCampaignService.getRedeemCount('202501-bone')
  ])
  .then(([mgmCouponResponse, redeemCountResponse]) => {
    couponCode.value = mgmCouponResponse?.couponId
    referrals.value = redeemCountResponse?.redeemCount
  })
  .catch(() => {
    couponCode.value = null
    referrals.value = null
  })
}

const copyCouponToClipboard = () => {
  const coupon = couponCode.value

  if (coupon) {
    copyToClipboard(coupon)

    notifications.success('Cupom de desconto copiado!')
  }
}

await reload()
</script>
<style lang="sass" scoped>
@import "~/assets/styles/mixins"
@import "~/assets/styles/variables"

.award-progress-banner
  position: relative
  max-width: 1440px
  margin: 0 auto
  margin-bottom: 48px
  width: 100%

  .progress-bar
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    width: 100%
    height: 90px
    background-color: $magenta

    .progress-bar-content
      position: relative
      display: flex
      flex-direction: row
      justify-content: space-between
      align-items: center
      width: 100%
      height: auto
      max-width: 980px

      .progress-bar-text
        color: white
        font-size: 22px

      .progress-bar-dots
        display: flex
        flex-direction: row
        justify-content: flex-start
        align-items: center

        .progress-bar-dot
          position: relative
          width: 190px
          height: 58px

          .icon
            position: absolute
            bottom: 0
            left: 3px
            width: auto
            height: 25px
            opacity: 0.5

          .check
            display: none
            position: absolute
            top: 7px
            left: 5px
            z-index: 10

          &::before
            content: ''
            position: absolute
            top: 12px
            left: 0
            width: 100%
            height: 4px
            background-color: #FF94C3

          &::after
            content: ''
            position: absolute
            width: 28px
            height: 28px
            border-radius: 50%
            background-color: #FF94C3
            top: 0
            left: 0
            box-sizing: border-box

          &:last-child
            width: 28px

            &::before
              display: none

          &.current
            .icon
              opacity: 1

            &::after
              border: 2px solid white

          &.filled
            .icon
              opacity: 1

            .check
              display: inline-block

            &::before
              background-color: white

            &::after
              background-color: white

  .banner
    position: relative

    .banner-background
      img
        width: 100%
        height: auto

      .banner-background-mobile
        display: none

    .banner-content
      position: absolute
      height: 100%
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      gap: 32px
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      color: white

      .banner-subtext
        max-width: 590px
        font-size: 24px
        text-align: center

      .banner-coupon
        display: flex
        flex-direction: column
        align-items: center

        .banner-coupon-code
          display: inline-flex
          flex-direction: column
          justify-content: center
          align-items: center
          width: 282px
          height: 72px
          text-align: center
          font-size: 32px
          font-weight: 800
          background-color: white
          color: $magenta
          padding: 8px 16px
          border: none
          border-radius: 36px
          margin-top: 8px
          cursor: pointer
          user-select: none

      .banner-notice
        position: absolute
        bottom: 48px
        max-width: 440px
        text-align: center

@media (min-width: 1450px)
  .award-progress-banner
    .progress-bar
      border-radius: 16px 16px 0 0
      overflow: hidden

    .banner
      .banner-background
        img
          border-radius: 0 0 16px 16px
          overflow: hidden

@mixin scaleDesktop($scale)
  .award-progress-banner
    margin-bottom: 24px

    .progress-bar
      height: $scale * 90px

      .progress-bar-content
        max-width: $scale * 980px

        .progress-bar-text
          font-size: $scale * 22px

        .progress-bar-dots

          .progress-bar-dot
            width: $scale * 190px
            height: $scale * 58px

            .icon
              left: $scale * 3px
              height: $scale * 25px

            .check
              top: $scale * 7px
              left: $scale * 5px
              height: $scale * 15px

            &::before
              top: $scale * 12px
              height: $scale * 4px

            &::after
              width: $scale * 28px
              height: $scale * 28px

            &:last-child
              width: $scale * 28px

    .banner

      .banner-content
        gap: $scale * 32px

        .banner-headline
          width: $scale * 780px

        .banner-subtext
          max-width: $scale * 590px
          font-size: $scale * 24px

        .banner-coupon

          .banner-coupon-code
            width: $scale * 282px
            height: $scale * 72px
            font-size: $scale * 32px
            padding: ($scale * 8px) ($scale * 16px)
            border-radius: $scale * 36px
            margin-top: $scale * 8px

        .banner-notice
          font-size: $scale * 16px
          bottom: $scale * 48px
          max-width: $scale * 440px

@media (max-width: 1280px)
  @include scaleDesktop(0.85)

@include tablet
  @include scaleDesktop(0.6)

@include phablet
  $scale: 0.6

  .award-progress-banner

    .progress-bar
      height: 90px

      .progress-bar-content
        flex-direction: column
        gap: 16px

        .progress-bar-text
          font-size: 14px

        .progress-bar-dots

          .progress-bar-dot
            width: 114px
            height: 35px

            .icon
              left: 2px
              height: 15px

            .check
              top: 4px
              left: 3px
              height: 9px

            &::before
              top: 7px
              height: 3px

            &::after
              width: 17px
              height: 17px

            &:last-child
              width: 17px

    .banner
      .banner-background
        .banner-background-desktop
          display: none

        .banner-background-mobile
          display: inline

      .banner-content
        gap: 18px
        text-shadow: 0 0 2px rgba(0, 0, 0, 0.5)

        .banner-headline
          width: 100vw

        .banner-subtext
          width: 90vw
          max-width: 354px
          font-size: 14px

        .banner-coupon

          .banner-coupon-code
            width: 282px
            height: 36px
            font-size: 18px
            padding: 5px
            border-radius: 18px
            margin-top: 8px
            text-shadow: none

        .banner-notice
          font-size: 12px
          bottom: 32px
          width: 90vw
          max-width: 354px
</style>
